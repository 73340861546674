<template>
<div class="apply-page">
  <Topbar2></Topbar2>
  <div class="login-page">
    <div class="bg"></div>
    <div class="ikon">
      <div class="reset-form">
          <img src="@/assets/plaint.png" class="plaint">
          <p>您的供应商权限正在审核中！</p>
          <router-link tag="div" to="./homepage"><span class="nextbtn" >返回首页</span></router-link>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Topbar2 from '@/components/topbar2'
export default {
  name: "supchecking",
  // inject:['app'],
  components: { 
  Topbar2
  },
  data() {
    return {
      moble: "",
      idnum:"",
      isreason:"false",
      code:""
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
  },
  methods: {
    
  }
};
</script>

<style lang="less" scoped>
.apply-page {
  position: relative;
  padding: 0px;
  margin: 0;
  width: 100%;}
.login-page {
  top: 80px;
  position: fixed;
  padding: 0px;
  margin: 0;
  font-size: 0px;
  height: 720px;
  width: 100%;
  box-sizing: border-box;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(242, 243, 250, 1);
    z-index: -10;
  }
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: -70px;
  > img {
    width: 100%;
    height: 100%;
  }
}

.ikon {
  position: absolute;
  z-index: 5;
  padding: 0;
  align-items: center;
  width: 1300px;
  height: 90%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(222, 222, 222, 1);
  border-radius: 8px;
  > img {
    width: 570px;
    height: 100%;
  }
.plaint {width: 378px;height: 120px;margin-left: 310px;display: inline-block;}
  .reset-form {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 0;
    top: 100px;
    margin: auto;
    height: 400px;
    width: 1000px;
    background-color: #fff;
    box-sizing: border-box;
    > p {
      height: 100px;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 100px;
      text-align: center;
      margin: 20px auto;
    }
    >div {
      font-size:20px;
      font-family:Microsoft YaHei;
      font-weight:400;
      display: block;
      height: 34px;
      line-height: 34px;
      text-align: center;
    }
    .nextbtn {
      width: 360px;
      height: 60px;
      background: rgba(55, 67, 214, 1);
      border-radius: 30px;
      display: inline-block;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      line-height: 60px;
      text-align: center;
      margin:60px 0 0 5px;
      cursor: pointer;
    }
  }
}
</style>