<template>
    <checking />
</template>

<script>
// @ is an alias to /src
import checking from "@/components/supplier_apply/checking.vue";
export default {
  name: "sup_check",
  components: {
    checking,
  }
};
</script>